@tailwind base;
@tailwind components;

.inp-border-primary {
  @apply border-gray-300 focus:ring-primary-500 focus:border-primary-500;
}

.inp-border-red {
  @apply border-red-300 focus:ring-red-500 focus:border-red-500;
}

.slider-thumb::-webkit-slider-thumb {
  @apply w-4 h-4 rounded-full appearance-none cursor-pointer bg-primary-500;
}

.slider-thumb::-webkit-slider-thumb:hover {
  @apply bg-primary-700;
}

/* width */
::-webkit-scrollbar {
  @apply w-3 h-3;
}

/* Handle */
::-webkit-scrollbar-thumb {
  @apply bg-gray-400 rounded-xl;
}
::-webkit-scrollbar-thumb:hover {
  @apply bg-gray-500 rounded-xl;
}

/*
  w-md-editor
*/

.w-md-editor-content {
  @apply pb-1;
}
.wmde-markdown ul {
  list-style: none !important;
}
.wmde-markdown ol, .wmde-markdown ul {
  padding-left: 0em !important;
}

@tailwind utilities;
